/* eslint-disable import/order */
import {
  RouteRecordRaw,
  createRouter,
  createWebHistory,
} from 'vue-router';
import {
  CONFIG,
  CONSTANTS,
  IS_DEVELOPMENT,
  trackGoogleTagEvent,
} from '@/helpers';
import {
  useStore,
} from '@/store';
import Bus, {
  NOTIFICATION,
} from '@/bus';

// REFERENCE: https://router.vuejs.org/guide/advanced/lazy-loading.html
/**
 * NOTE: For every new webpackChunkName, that chunk name should be added to `LazyScript.vue` file
 */

// TODO: remove home from common view as it is for guest user
// Common Home (Public only)
const LandingPage = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "home" */
  '@/views/Common/LandingPage.vue'
);

// Common Utilities
const PrivacyPolicy = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "privacy-policy" */
  '@/views/Common/PrivacyPolicy.vue'
);
const RefundPolicy = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "refund-policy" */
  '@/views/Common/RefundPolicy.vue'
);
const TermsOfService = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "terms-of-service" */
  '@/views/Common/TermsOfService.vue'
);
const Faq = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "faq" */
  '@/views/Common/FaqSection.vue'
);
const AboutUs = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "about-us" */
  '@/views/Common/AboutUs.vue'
);
const NewsView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "news" */
  '@/views/Common/NewsView.vue'
);

// Common Courses
const OurCoursesView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "our-courses" */
  '@/views/Common/Course/OurCourses.vue'
);
const FreeCoursesView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "free-courses" */
  '@/views/Common/Course/FreeCourses.vue'
);
const DynamicPagesView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "dynamic-pages" */
  '@/views/Common/Course/DynamicPages.vue'
);
const CourseDetailsView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "course-details" */
  '@/views/Common/Course/CourseDetails.vue'
);

// Auth
const AuthView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "auth" */
  '@/views/Auth/AuthView.vue'
);
const LoginView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "auth" */
  '@/views/Auth/LoginView.vue'
);
const SignupView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "auth" */
  '@/views/Auth/SignupView.vue'
);
const ResetPassword = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "auth" */
  '@/views/Auth/ResetPassword.vue'
);
const VerifyResetPassword = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "auth" */
  '@/views/Auth/VerifyResetPassword.vue'
);
const ActivateEmail = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "auth" */
  '@/views/Auth/ActivateEmail.vue'
);
const VerifyEmail = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "auth" */
  '@/views/Auth/VerifyEmail.vue'
);
const GoogleLogin = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "google-login" */
  '@/views/Auth/GoogleLogin.vue'
);

// End User Utilities
const ContactView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "user-utilities-view" */
  '@/views/EndUser/ContactView.vue'
);

// End User Courses
const DashboardView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "user-dashboard-view" */
  '@/views/EndUser/DashboardView.vue'
);
const EnrolledCoursesView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "user-enrolled-courses" */
  '@/views/EndUser/Course/EnrolledCourses.vue'
);
const RunningCoursesView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "user-running-courses" */
  '@/views/EndUser/Course/RunningCourses.vue'
);
const UpcomingCoursesView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "user-upcoming-courses" */
  '@/views/EndUser/Course/UpcomingCourses.vue'
);

// End User Contents
const CourseMaterialsView = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "course-materials" */
  '@/views/EndUser/Content/CourseMaterials.vue'
);
const UserExamView = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "user-exam" */
  '@/views/EndUser/Content/UserExam.vue'
);
const UserAnswerView = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "user-answer" */
  '@/views/EndUser/Content/UserAnswer.vue'
);
const LeaderBoardView = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "leader-board" */
  '@/views/EndUser/Content/LeaderBoard.vue'
);

// End User Account
const UserView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "user-account-view" */
  '@/views/EndUser/UserView.vue'
);
const AccountView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "user-account-view" */
  '@/views/EndUser/AccountView.vue'
);
const AccountEdit = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "user-account-view" */
  '@/views/EndUser/AccountEdit/AccountEdit.vue'
);
const EditProfile = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "user-account-view" */
  '@/views/EndUser/AccountEdit/EditProfile.vue'
);
const EditGuardian = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "user-account-view" */
  '@/views/EndUser/AccountEdit/EditGuardian.vue'
);

const isGuest = {
  meta: {
    isGuest: true,
  },
};

const isCommon = {
  meta: {
    isCommon: true,
  },
};

const routes: Array<RouteRecordRaw> = [
  {
    path: CONSTANTS.ROUTE.home.path,
    name: CONSTANTS.ROUTE.home.title,
    component: LandingPage,
    meta: {
      title: CONSTANTS.ROUTE.home.title,
      alternateTitle: CONSTANTS.ROUTE.home.alternateTitle,
      ...isCommon.meta,
    },
  },
  {
    path: '/auth',
    name: 'Auth',
    component: AuthView,
    children: [
      {
        path: 'login',
        name: 'Login',
        component: LoginView,
        meta: {
          title: 'Login',
          alternateTitle: 'লগ ইন করো',
          ...isGuest.meta,
        },
      },
      {
        path: 'signup',
        name: 'SignUp',
        component: SignupView,
        meta: {
          title: 'Sign Up',
          alternateTitle: 'সাইন আপ করো',
          ...isGuest.meta,
        },
      },
      {
        path: 'reset-password',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: {
          title: 'Reset Password',
          alternateTitle: 'রিসেট পাসওয়ার্ড',
          ...isGuest.meta,
        },
      },
      {
        path: 'verify-reset-password/:id',
        name: 'VerifyResetPassword',
        component: VerifyResetPassword,
        meta: {
          title: 'Verify Reset Password',
          ...isGuest.meta,
        },
      },
      {
        path: 'activate-email',
        name: 'ActivateEmail',
        component: ActivateEmail,
        meta: {
          title: 'Activate Email',
          alternateTitle: 'এক্টিভেট ইমেইল',
          ...isGuest.meta,
        },
      },
      {
        path: 'verify-email/:token',
        name: 'VerifyEmail',
        component: VerifyEmail,
        meta: {
          title: 'Verify Email',
          alternateTitle: 'ভেরিফাই ইমেইল',
          ...isGuest.meta,
        },
      },
    ],
  },
  {
    path: '/login/google',
    name: 'GoogleLogin',
    component: GoogleLogin,
    meta: {
      title: 'Google Login',
      ...isGuest.meta,
    },
  },
  {
    path: CONSTANTS.ROUTE.privacyPolicy.path,
    name: CONSTANTS.ROUTE.privacyPolicy.title,
    component: PrivacyPolicy,
    meta: {
      title: CONSTANTS.ROUTE.privacyPolicy.title,
      alternateTitle: CONSTANTS.ROUTE.privacyPolicy.alternateTitle,
      ...isCommon.meta,
    },
  },
  {
    path: CONSTANTS.ROUTE.refundPolicy.path,
    name: CONSTANTS.ROUTE.refundPolicy.title,
    component: RefundPolicy,
    meta: {
      title: CONSTANTS.ROUTE.refundPolicy.title,
      alternateTitle: CONSTANTS.ROUTE.refundPolicy.alternateTitle,
      ...isCommon.meta,
    },
  },
  {
    path: CONSTANTS.ROUTE.termsOfService.path,
    name: CONSTANTS.ROUTE.termsOfService.title,
    component: TermsOfService,
    meta: {
      title: CONSTANTS.ROUTE.termsOfService.title,
      alternateTitle: CONSTANTS.ROUTE.termsOfService.alternateTitle,
      ...isCommon.meta,
    },
  },
  {
    path: CONSTANTS.ROUTE.faq.path,
    name: CONSTANTS.ROUTE.faq.title,
    component: Faq,
    meta: {
      title: CONSTANTS.ROUTE.faq.title,
      alternateTitle: CONSTANTS.ROUTE.faq.alternateTitle,
      ...isCommon.meta,
    },
  },
  {
    path: CONSTANTS.ROUTE.aboutUs.path,
    name: CONSTANTS.ROUTE.aboutUs.title,
    component: AboutUs,
    meta: {
      title: CONSTANTS.ROUTE.aboutUs.title,
      alternateTitle: CONSTANTS.ROUTE.aboutUs.alternateTitle,
      ...isCommon.meta,
    },
  },
  {
    path: CONSTANTS.ROUTE.news.path,
    name: CONSTANTS.ROUTE.news.title,
    component: NewsView,
    meta: {
      title: CONSTANTS.ROUTE.news.title,
      alternateTitle: CONSTANTS.ROUTE.news.alternateTitle,
      ...isCommon.meta,
    },
  },
  {
    path: CONSTANTS.ROUTE.userContact.path,
    name: CONSTANTS.ROUTE.userContact.title,
    component: ContactView,
    meta: {
      title: CONSTANTS.ROUTE.userContact.title,
      alternateTitle: CONSTANTS.ROUTE.userContact.alternateTitle,
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardView,
    meta: {
      title: 'Dashboard',
      alternateTitle: 'ড্যাশবোর্ড',
    },
  },
  {
    path: '/materials/course/:courseId',
    props: (route) => ({
      courseId: route.params.courseId,
    }),
    name: 'CourseMaterials',
    component: CourseMaterialsView,
    meta: {
      title: 'Course Materials',
      alternateTitle: 'কোর্স ম্যাটেরিয়ালস',
    },
  },
  {
    path: '/user-exam/:examId',
    props: (route) => ({
      examId: route.params.examId,
      showPreview: route.query.showPreview,
      isLastAttempt: route.query.last,
    }),
    name: 'UserExam',
    component: UserExamView,
    meta: {
      title: 'User Exam',
      alternateTitle: 'শিক্ষার্থী এক্সাম',
    },
  },
  {
    path: '/user-answer/:answerId',
    props: (route) => ({
      answerId: route.params.answerId,
    }),
    name: 'UserAnswer',
    component: UserAnswerView,
    meta: {
      title: 'User Answer',
      alternateTitle: 'শিক্ষার্থী উত্তর',
    },
  },
  {
    path: '/leader-board/:quizId',
    props: (route) => ({
      quizId: route.params.quizId,
    }),
    name: 'LeaderBoard',
    component: LeaderBoardView,
    meta: {
      title: 'Leader Board',
      alternateTitle: 'লিডারবোর্ড',
    },
  },
  {
    path: CONSTANTS.ROUTE.myEnrolledCourses.path,
    name: CONSTANTS.ROUTE.myEnrolledCourses.title,
    props: (route) => ({
      notification: route.query.notification,
    }),
    component: EnrolledCoursesView,
    meta: {
      title: CONSTANTS.ROUTE.myEnrolledCourses.title,
      alternateTitle: CONSTANTS.ROUTE.home.alternateTitle,
    },
  },
  {
    path: CONSTANTS.ROUTE.myUpcomingCourses.path,
    name: CONSTANTS.ROUTE.myUpcomingCourses.title,
    component: UpcomingCoursesView,
    meta: {
      title: CONSTANTS.ROUTE.myUpcomingCourses.title,
      alternateTitle: CONSTANTS.ROUTE.myUpcomingCourses.alternateTitle,
    },
  },
  {
    path: CONSTANTS.ROUTE.myRunningCourses.path,
    name: CONSTANTS.ROUTE.myRunningCourses.title,
    component: RunningCoursesView,
    meta: {
      title: CONSTANTS.ROUTE.myRunningCourses.title,
      alternateTitle: CONSTANTS.ROUTE.myRunningCourses.alternateTitle,
    },
  },
  {
    path: '/our-courses/:filter?',
    name: CONSTANTS.ROUTE.allOurCourses.title,
    component: OurCoursesView,
    meta: {
      title: CONSTANTS.ROUTE.allOurCourses.title,
      alternateTitle: CONSTANTS.ROUTE.allOurCourses.alternateTitle,
      ...isCommon.meta,
    },
  },
  {
    path: CONSTANTS.ROUTE.freeCourses.path,
    name: CONSTANTS.ROUTE.freeCourses.title,
    component: FreeCoursesView,
    meta: {
      title: CONSTANTS.ROUTE.freeCourses.title,
      alternateTitle: CONSTANTS.ROUTE.freeCourses.alternateTitle,
      ...isCommon.meta,
    },
  },
  {
    path: `${CONSTANTS.ROUTE.dynamicPages.path}/:slug`,
    props: (route) => ({
      slug: route.params.slug,
    }),
    name: CONSTANTS.ROUTE.dynamicPages.title,
    component: DynamicPagesView,
    meta: {
      title: CONSTANTS.ROUTE.dynamicPages.title,
      alternateTitle: CONSTANTS.ROUTE.dynamicPages.alternateTitle,
      ...isCommon.meta,
    },
  },
  {
    path: '/course/:slug',
    props: (route) => ({
      slug: route.params.slug,
      notification: route.query.notification,
      showPreview: route.query.showPreview,
    }),
    name: 'CourseDetails',
    component: CourseDetailsView,
    meta: {
      title: 'Course Details',
      alternateTitle: 'কোর্স ডিটেইলস',
      ...isCommon.meta,
    },
  },
  {
    path: '/user',
    name: 'User',
    component: UserView,
    children: [
      {
        path: 'account/view',
        name: CONSTANTS.ROUTE.myAccount.title,
        component: AccountView,
        meta: {
          title: CONSTANTS.ROUTE.myAccount.title,
          alternateTitle: CONSTANTS.ROUTE.myAccount.alternateTitle,
        },
      },
      {
        path: 'account/edit',
        name: 'AccountEdit',
        component: AccountEdit,
        children: [
          {
            path: 'profile',
            name: 'EditProfile',
            component: EditProfile,
            meta: {
              title: 'Edit Profile',
              alternateTitle: 'এডিট প্রোফাইল',
            },
          },
          {
            path: 'guardian',
            name: 'EditGuardian',
            component: EditGuardian,
            meta: {
              title: 'Edit Guardian',
              alternateTitle: 'এডিট অভিভাবক',
            },
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          const element = document.querySelector(to.hash);
          if (element) {
            element.scrollIntoView({
              behavior: 'smooth',
            });
            resolve();
          } else {
            resolve({
              top: 0,
            });
          }
        }, 500);
      });
    } if (savedPosition) {
      return savedPosition;
    }
    return {
      top: 0,
    };
  },
});

router.beforeEach(async (to, from, next) => {
  useStore.app.prevRoutePath = from.fullPath;
  useStore.app.isLoading = true;

  // ensuring user load from local storage attempted before routing
  await useStore.app.init();
  next();
});

router.afterEach((to, from, failure) => {
  useStore.app.isLoading = false;

  const { user } = useStore.auth;

  const userHighlight = user ? `${user.username} - ${user.phoneNo}` : 'N/A';

  trackGoogleTagEvent('page_change', 'change', `user = ${
    userHighlight
  } & to = ${
    to.meta.title
  } & from = ${
    from.meta?.title
  } && failure = ${
    !!failure
  }`);
});

router.beforeResolve((to, from, next) => {
  const { user } = useStore.auth;

  let titleSuffix = user && IS_DEVELOPMENT
    ? ` | ${user.firstName}`
    : '';

  if (to.meta?.title) {
    titleSuffix = ` - ${to.meta?.title}${titleSuffix}`;
  }
  document.title = `${CONFIG.appName}${titleSuffix}`;

  if (!user && !to.meta?.isGuest && !to.meta?.isCommon) {
    next(
      `/auth/${from.meta?.isCommon ? 'signup' : 'login'
      }?redirect=${encodeURIComponent(to.fullPath)}`,
    );
    return;
  }

  const shouldRedirectToMemberHome = to.meta?.isGuest;

  if (user) {
    const accountEditPath = '/user/account/edit';

    if (!user.fbLink
      && !to.path.startsWith(accountEditPath)
    ) {
      Bus.emit(NOTIFICATION.INFO, {
        message: 'অন্য পেজগুলো ঘুরে দেখার আগে অনুগ্রহ করে তোমার প্রোফাইলটি সম্পূর্ণ করো',
      });

      if (!from.path.startsWith(accountEditPath)) {
        next(`${accountEditPath}/profile`);
      } else {
        useStore.app.isLoading = false;
      }
      return;
    }

    if (shouldRedirectToMemberHome) {
      next(CONSTANTS.ROUTE.DEFINED.MEMBER_HOME);
      return;
    }
  }

  next();
});

export { routes };

export default router;
